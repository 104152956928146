import { WikiRecommendationsVariant } from 'pathfinder/modules/OnWikiRecommendations/interfaces/WikiRecommendationsVariants';
import _operators from '../targetingRules/_operators';
import { ARTICLES_RULE, DESKTOP_ANON_MEDIAWIKI_RULESET, LANGUAGE_EN_RULE, MOBILE_ANON_MEDIAWIKI_ARTICLE_RULESET, NO_EDIT_MODE_RULESET, } from '../targetingRules/_templates';
import { InsertType, ModuleType } from '../types';
const targetingDesktop = _operators.all(DESKTOP_ANON_MEDIAWIKI_RULESET, ARTICLES_RULE, LANGUAGE_EN_RULE, NO_EDIT_MODE_RULESET);
const targetingMobile = _operators.all(MOBILE_ANON_MEDIAWIKI_ARTICLE_RULESET, ARTICLES_RULE, LANGUAGE_EN_RULE, NO_EDIT_MODE_RULESET);
const onWikiRecommendationsModules = [
    // {
    // 	name: 'desktop-related-wiki-recommendations',
    // 	type: ModuleType.OnWikiRecommendations,
    // 	insertConfig: { type: InsertType.Custom },
    // 	config: {
    // 		variant: WikiRecommendationsVariant.DesktopSameVertical,
    // 	},
    // 	targeting: targetingDesktop,
    // },
    {
        name: 'mobile-related-wiki-recommendations',
        type: ModuleType.OnWikiRecommendations,
        insertConfig: { type: InsertType.Custom },
        config: {
            variant: WikiRecommendationsVariant.MobileSameVertical,
        },
        targeting: targetingMobile,
    },
    // {
    // 	name: 'new-related-wiki-recommendations-control',
    // 	type: ModuleType.OnWikiRecommendations,
    // 	insertConfig: { type: InsertType.Custom },
    // 	buckets: [],
    // 	expBucket: ['9', '10', '34', '35', '52', '53'],
    // 	config: {
    // 		variant: WikiRecommendationsVariant.NewOthersLikeYouWidgetControl,
    // 	},
    // 	targeting: targetingDesktop,
    // },
    {
        name: 'new-others-like-you-also-viewed',
        type: ModuleType.OnWikiRecommendations,
        insertConfig: { type: InsertType.Custom },
        config: {
            variant: WikiRecommendationsVariant.NewOthersLikeYouWidget,
        },
        targeting: targetingDesktop,
    },
    // {
    // 	name: 'new-just-for-you',
    // 	type: ModuleType.OnWikiRecommendations,
    // 	insertConfig: { type: InsertType.Custom },
    // 	buckets: [],
    // 	expBucket: ['84', '86', '90'],
    // 	config: {
    // 		variant: WikiRecommendationsVariant.NewJustForYouWidget,
    // 	},
    // 	targeting: targetingDesktop,
    // },
];
export default onWikiRecommendationsModules;
