import { InsertElementMethod, InsertType, ModuleType, TargetingType, } from 'pathfinder/rules-engine/types';
import _operators from 'pathfinder/rules-engine/targetingRules/_operators';
import { DESKTOP_RULE, F2_RULE, MEDIAWIKI_RULE, NAMESPACE_0_RULE, USER_ANON_RULE, } from 'pathfinder/rules-engine/targetingRules/_templates';
const INSERT_CONFIG = {
    type: InsertType.Element,
    selectors: [
        {
            selector: '.global-navigation__links',
            method: InsertElementMethod.Prepend,
        },
    ],
};
const ADULT_RULE = {
    type: TargetingType.FandomContext,
    context: 'site',
    property: 'directedAtChildren',
    value: false,
};
const sponsoredWidgetsSponsoredLogoConsumptionModules = [
    {
        name: 'sponsored-widgets-sponsored-logo-consumption-module',
        type: ModuleType.SponsoredWidgetsSponsoredLogoConsumptionModule,
        insertConfig: INSERT_CONFIG,
        config: {
            sponsoredLogos: [],
        },
        targeting: _operators.any(_operators.all(ADULT_RULE, USER_ANON_RULE, DESKTOP_RULE, MEDIAWIKI_RULE, NAMESPACE_0_RULE), _operators.all(USER_ANON_RULE, DESKTOP_RULE, F2_RULE)),
    },
];
export default sponsoredWidgetsSponsoredLogoConsumptionModules;
