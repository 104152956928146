import { ModuleType, InsertType } from '../types';
import _op from 'pathfinder/rules-engine/targetingRules/_operators';
import { DESKTOP_RULE, LANGUAGE_EN_RULE, MEDIAWIKI_RULE, USER_LOGGED_IN_RULE, } from 'pathfinder/rules-engine/targetingRules/_templates';
export const IMPACT_NOTIFICATIONS_VARIANT_TO_COOKIE = {
    'impact-notifications-control': 'control',
    'impact-notifications-variant-A': 'A',
    'impact-notifications-variant-B': 'B',
};
const TARGETING_RULES = _op.all(USER_LOGGED_IN_RULE, MEDIAWIKI_RULE, DESKTOP_RULE, LANGUAGE_EN_RULE);
const impactNotificationsModules = [
    {
        name: 'impact-notifications-control',
        type: ModuleType.ImpactNotifications,
        insertConfig: { type: InsertType.Custom },
        expBucket: [
            '32',
            '33',
            '34',
            '35',
            '36',
            '37',
            '38',
            '39',
            '40',
            '41',
            '42',
            '43',
            '44',
            '45',
            '46',
            '47',
            '48',
            '49',
            '50',
            '51',
            '52',
            '53',
            '90',
            '91',
            '92',
            '93',
            '93',
            '94',
            '95',
            '96',
            '97',
            '98',
        ],
        config: {},
        targeting: TARGETING_RULES,
    },
    {
        name: 'impact-notifications-variant-A',
        type: ModuleType.ImpactNotifications,
        insertConfig: { type: InsertType.Custom },
        expBucket: [
            '0',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '30',
            '31',
        ],
        config: {},
        targeting: TARGETING_RULES,
    },
    {
        name: 'impact-notifications-variant-B',
        type: ModuleType.ImpactNotifications,
        insertConfig: { type: InsertType.Custom },
        expBucket: [
            '56',
            '57',
            '58',
            '59',
            '60',
            '61',
            '62',
            '63',
            '64',
            '65',
            '67',
            '68',
            '69',
            '70',
            '71',
            '72',
            '73',
            '74',
            '75',
            '76',
            '77',
            '78',
            '79',
            '80',
            '81',
            '82',
            '83',
            '84',
            '85',
            '86',
            '87',
            '88',
        ],
        config: {},
        targeting: TARGETING_RULES,
    },
];
export default impactNotificationsModules;
