import { ModuleType, InsertType } from '../types';
import _op from 'pathfinder/rules-engine/targetingRules/_operators';
import { MEDIAWIKI_RULE, MW_MOBILE_SKIN_RULE, MOBILE_RULE, USER_ANON_RULE, PRODUCT_SKIN, CHROME_SAFARI_RULE, ARTICLES_RULE, } from 'pathfinder/rules-engine/targetingRules/_templates';
const TARGETING_RULES = _op.all(USER_ANON_RULE, MEDIAWIKI_RULE, _op.any(MW_MOBILE_SKIN_RULE, PRODUCT_SKIN), MOBILE_RULE, CHROME_SAFARI_RULE, ARTICLES_RULE);
const disabled = false;
const mobileDrawerPersistenceModules = [
    {
        disabled,
        name: 'mobile-drawer-persistence-control',
        type: ModuleType.MobileDrawerPersistence,
        insertConfig: { type: InsertType.Custom },
        expBucket: ['59', '60', '61'],
        config: {},
        targeting: TARGETING_RULES,
    },
    {
        disabled,
        name: 'mobile-drawer-persistence-v1',
        type: ModuleType.MobileDrawerPersistence,
        insertConfig: { type: InsertType.Custom },
        expBucket: ['62', '63', '55'],
        config: {},
        targeting: TARGETING_RULES,
    },
    {
        disabled,
        name: 'mobile-drawer-persistence-v2',
        type: ModuleType.MobileDrawerPersistence,
        insertConfig: { type: InsertType.Custom },
        expBucket: ['56', '65', '64'],
        config: {},
        targeting: TARGETING_RULES,
    },
    {
        disabled,
        name: 'mobile-drawer-persistence-v3',
        type: ModuleType.MobileDrawerPersistence,
        insertConfig: { type: InsertType.Custom },
        expBucket: ['72', '73', '74'],
        config: {},
        targeting: TARGETING_RULES,
    },
];
export default mobileDrawerPersistenceModules;
