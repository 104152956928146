import { ModuleType, InsertType } from '../types';
import _op from 'pathfinder/rules-engine/targetingRules/_operators';
import { MEDIAWIKI_RULE, MW_MOBILE_SKIN_RULE, MOBILE_RULE, USER_ANON_RULE, PRODUCT_SKIN, CHROME_SAFARI_RULE, ARTICLES_RULE, } from 'pathfinder/rules-engine/targetingRules/_templates';
const TARGETING_RULES = _op.all(USER_ANON_RULE, MEDIAWIKI_RULE, _op.any(MW_MOBILE_SKIN_RULE, PRODUCT_SKIN), MOBILE_RULE, CHROME_SAFARI_RULE, ARTICLES_RULE);
const disabled = true; // just disabling it for now.
const mobileDrawerModules = [
    {
        disabled,
        name: 'mobile-drawer-control',
        type: ModuleType.MobileDrawer,
        insertConfig: { type: InsertType.Custom },
        expBucket: ['87', '90', '92'],
        config: {},
        targeting: TARGETING_RULES,
    },
    {
        disabled,
        name: 'mobile-drawer-v1',
        type: ModuleType.MobileDrawer,
        insertConfig: { type: InsertType.Custom },
        expBucket: ['16', '25', '32'],
        config: {},
        targeting: TARGETING_RULES,
    },
    {
        disabled,
        name: 'mobile-drawer-v2',
        type: ModuleType.MobileDrawer,
        insertConfig: { type: InsertType.Custom },
        expBucket: ['63', '35', '27'],
        config: {},
        targeting: TARGETING_RULES,
    },
    {
        disabled,
        name: 'mobile-drawer-v3',
        type: ModuleType.MobileDrawer,
        insertConfig: { type: InsertType.Custom },
        expBucket: ['52', '76', '82'],
        config: {},
        targeting: TARGETING_RULES,
    },
    {
        disabled,
        name: 'mobile-drawer-v4',
        type: ModuleType.MobileDrawer,
        insertConfig: { type: InsertType.Custom },
        expBucket: ['61', '68', '70'],
        config: {},
        targeting: TARGETING_RULES,
    },
];
export default mobileDrawerModules;
